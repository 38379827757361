import { Category, ErpSystem, FormField } from "../types";

interface PageViewPayload {
  pageUrl: string;
  pagePath: string;
  pageHostname: string;
  organizationId?: string;
  organizationName?: string;
  userSalesChannel?: string;
  userType: string;
  userId: string;
  userSegment: string;
  loggedIn: boolean;
}
type Payload = {
  isReadyToSendEvents?: boolean;
  sendPageViewEvent(payload: PageViewPayload): void;
  erpSystem: string;
  loginLoading: boolean;
  loggedIn: boolean;
  userType: string;
  userId: string;
  userSegment: string;
  customerNumber: string;
  organizationName: string;
  userSalesChannel: string;
};

export const sendViewEvent = ({
  isReadyToSendEvents,
  sendPageViewEvent,
  erpSystem,
  loginLoading,
  loggedIn,
  userType,
  userId,
  userSegment,
  customerNumber,
  organizationName,
  userSalesChannel,
}: Payload) => {
  if (sendPageViewEvent && loginLoading === false) {
    sendPageViewEvent({
      pageUrl: window.location.href,
      pagePath: window.location.pathname,
      pageHostname: window.location.hostname,
      organizationId: customerNumber || "",
      organizationName: organizationName || "",
      userSalesChannel,
      userType,
      userId,
      userSegment,
      loggedIn,
    });
  }
};

type CategoriesMap = {
  [k: string]: Category[];
};

const sheetFields: FormField[] = [
  {
    id: "materialTitle",
    component: "title",
  },
  {
    id: "eriksName",
    component: "select",
  },
  {
    id: "isoName",
    component: "select",
  },
  {
    id: "materialClass",
    component: "select",
  },
  {
    id: "dimensionsTitle",
    component: "title",
  },
  {
    id: "thickness",
    component: "select",
  },
  {
    id: "length",
    component: "text",
    attributeId: "lengthRange",
    disabledIf: "materialClass|thickness",
    showModalIf: "materialClass|thickness",
  },
  {
    id: "width",
    component: "text",
    attributeId: "widthRange",
    disabledIf: "materialClass|thickness",
    showModalIf: "materialClass|thickness",
  },
  {
    id: "colour",
    component: "colour",
  },
];

const createCHSheetFields = (fields: FormField[]) => {
  return fields.map((field) => {
    if (field.id === "length" || field.id === "width") {
      return { ...field, disabledIf: "thickness", showModalIf: "thickness" };
    }
    return field;
  }); // check if it is not title field first
};

const chSheetFields: FormField[] = createCHSheetFields(sheetFields);

const gasketFields: FormField[] = [
  {
    id: "materialTitle",
    component: "title",
  },
  {
    id: "series",
    component: "select",
  },
  {
    id: "productType",
    component: "select",
  },
  {
    id: "dimensionsTitle",
    component: "title",
  },
  {
    id: "thickness",
    component: "select",
  },
  {
    id: "length",
    attributeId: "lengthRange",
    component: "text",
    disabledIf: "thickness",
    showModalIf: "thickness",
  },
  {
    id: "width",
    attributeId: "widthRange",
    component: "text",
    disabledIf: "thickness",
    showModalIf: "thickness",
  },
  {
    id: "colour",
    component: "colour",
  },
];

export const categoriesMap: CategoriesMap = {
  plastics: [
    {
      name: "sheet",
      basicForm: "sheet",
      availableIn: ["nl", "be", "uk"],
      fields: sheetFields,
    },
    {
      name: "tube",
      basicForm: "tube",
      availableIn: ["nl", "be", "uk"],
      fields: [
        {
          id: "materialTitle",
          component: "title",
        },
        {
          id: "eriksName",
          component: "select",
        },
        {
          id: "isoName",
          component: "select",
        },
        {
          id: "materialClass",
          component: "select",
        },
        {
          id: "dimensionsTitle",
          component: "title",
        },
        {
          id: "diameter",
          component: "select",
        },
        {
          id: "internalDiameter",
          component: "select",
        },
        {
          id: "length",
          component: "text",
          attributeId: "lengthRange",
          disabledIf: "materialClass|diameter",
          showModalIf: "materialClass|diameter",
        },
        {
          id: "colour",
          component: "colour",
        },
      ],
    },
    {
      name: "rod",
      basicForm: "rod",
      availableIn: ["nl", "be", "uk"],
      fields: [
        {
          id: "materialTitle",
          component: "title",
        },
        {
          id: "eriksName",
          component: "select",
        },
        {
          id: "isoName",
          component: "select",
        },
        {
          id: "materialClass",
          component: "select",
        },
        {
          id: "dimensionsTitle",
          component: "title",
        },
        {
          id: "diameter",
          component: "select",
        },
        {
          id: "length",
          component: "text",
          attributeId: "lengthRange",
          disabledIf: "materialClass|diameter",
          showModalIf: "materialClass|diameter",
        },
        {
          id: "colour",
          component: "colour",
        },
      ],
    },
    {
      name: "sheet",
      basicForm: "sheet",
      catId: "KST10",
      availableIn: ["ch"],
      fields: chSheetFields,
    },
    {
      name: "tube",
      basicForm: "tube",
      catId: "KST12",
      availableIn: ["ch"],
      fields: [
        {
          id: "materialTitle",
          component: "title",
        },
        {
          id: "eriksName",
          component: "select",
        },
        {
          id: "isoName",
          component: "select",
        },
        {
          id: "materialClass",
          component: "select",
        },
        {
          id: "dimensionsTitle",
          component: "title",
        },
        {
          id: "diameter",
          component: "select",
        },
        {
          id: "internalDiameter",
          component: "select",
        },
        {
          id: "length",
          component: "text",
          attributeId: "lengthRange",
          disabledIf: "diameter",
          showModalIf: "diameter",
        },
        {
          id: "colour",
          component: "colour",
        },
      ],
    },
    {
      name: "rod",
      basicForm: "rod",
      catId: "KST11",
      availableIn: ["ch"],
      fields: [
        {
          id: "materialTitle",
          component: "title",
        },
        {
          id: "eriksName",
          component: "select",
        },
        {
          id: "isoName",
          component: "select",
        },
        {
          id: "materialClass",
          component: "select",
        },
        {
          id: "dimensionsTitle",
          component: "title",
        },
        {
          id: "diameter",
          component: "select",
        },
        {
          id: "length",
          component: "text",
          attributeId: "lengthRange",
          disabledIf: "diameter",
          showModalIf: "diameter",
        },
        {
          id: "colour",
          component: "colour",
        },
      ],
    },
    {
      name: "strips",
      basicForm: "sheet",
      catId: "KST17",
      availableIn: ["ch"],
      fields: chSheetFields,
    },
    {
      name: "grate",
      basicForm: "grate",
      catId: "KST2101",
      availableIn: ["ch"],
      fields: [
        {
          id: "materialTitle",
          component: "title",
        },
        {
          id: "eriksName",
          component: "select",
        },
        {
          id: "isoName",
          component: "select",
        },
        {
          id: "materialClass",
          component: "select",
        },
        {
          id: "productType",
          component: "select",
        },
        {
          id: "dimensionsTitle",
          component: "title",
        },
        {
          id: "thickness",
          component: "select",
        },
        {
          id: "length",
          component: "text",
          attributeId: "lengthRange",
          disabledIf: "thickness",
          showModalIf: "thickness",
        },
        {
          id: "width",
          component: "text",
          attributeId: "widthRange",
          disabledIf: "thickness",
          showModalIf: "thickness",
        },
        {
          id: "colour",
          component: "colour",
        },
      ],
    },
    {
      name: "slides",
      basicForm: "sheet",
      catId: "KST14",
      availableIn: ["ch"],
      fields: chSheetFields,
    },
  ],
  seals: [
    {
      name: "fibreSheet",
      basicForm: "sheet", // should be sheet
      catId: "AFD100201",
      availableIn: ["ch"],
      fields: gasketFields, // should be gasketFields
    },
    {
      name: "ptfeSheet",
      basicForm: "sheet", // should be sheet
      catId: "AFD100301",
      availableIn: ["ch"],
      fields: gasketFields, // should be gasketFields
    },
    {
      name: "graphiteSheets",
      basicForm: "sheet", // should be sheet
      catId: "AFD100401",
      availableIn: ["ch"],
      fields: gasketFields, //should be gasketFields
    },
  ],
  insulation: [
    {
      name: "thermalInsulationBoards",
      basicForm: "sheet",
      catId: "ISM04",
      availableIn: ["ch"],
      fields: [
        {
          id: "materialTitle",
          component: "title",
        },
        {
          id: "series",
          component: "select",
        },
        {
          id: "productType",
          component: "select",
        },
        {
          id: "dimensionsTitle",
          component: "title",
        },
        {
          id: "thickness",
          component: "select",
        },
        {
          id: "length",
          attributeId: "lengthRange",
          component: "text",
          disabledIf: "thickness",
          showModalIf: "thickness",
        },
        {
          id: "width",
          attributeId: "widthRange",
          component: "text",
          disabledIf: "thickness",
          showModalIf: "thickness",
        },
        {
          id: "colour",
          component: "colour",
        },
      ],
    },
  ],
  elastomeric: [
    {
      name: "elastomerPlates",
      basicForm: "sheet",
      catId: "AFD100105",
      availableIn: ["ch"],
      fields: [
        {
          id: "materialTitle",
          component: "title",
        },
        {
          id: "series",
          component: "select",
        },
        {
          id: "productType",
          component: "select",
        },
        {
          id: "dimensionsTitle",
          component: "title",
        },
        {
          id: "thickness",
          component: "select",
        },
        {
          id: "length",
          attributeId: "lengthRange",
          component: "text",
          disabledIf: "thickness",
        },
        {
          id: "width",
          attributeId: "widthRange",
          component: "text",
          disabledIf: "thickness",
        },
        {
          id: "colour",
          component: "colour",
        },
      ],
    },
  ],
};

export const selectCategoriesForErpSystem: (
  erpSystem: ErpSystem,
  map: CategoriesMap
) => CategoriesMap = (erpSystem, catMap) => {
  const categories: CategoriesMap = {};
  for (const cat in catMap) {
    if (
      catMap[cat].find((subCat) =>
        subCat.availableIn.find((erp) => erp === erpSystem)
      )
    ) {
      categories[cat] = catMap[cat].filter((subCat) =>
        subCat.availableIn.find((erp) => erp === erpSystem)
      );
    }
  }
  return categories;
};

export type FieldSelector = {
  catId: string;
  erpSystem: ErpSystem;
  categoriesMap: CategoriesMap;
  basicForm: string;
};

export const selectFormFields: (
  arg0: FieldSelector
) => FormField[] | undefined = ({
  erpSystem,
  catId,
  categoriesMap,
  basicForm,
}) => {
  const categoriesForErpSystem = selectCategoriesForErpSystem(
    erpSystem,
    categoriesMap
  );

  const reducedCategory = Object.keys(categoriesForErpSystem).reduce(
    // @ts-ignore
    (acc, cur) => {
      if (
        categoriesForErpSystem[cur].find((categoryObj) => {
          const hasCatId: boolean = !!categoryObj.catId;
          if (hasCatId) {
            return (
              categoryObj.basicForm === basicForm && categoryObj.catId === catId
            );
          }
          return categoryObj.basicForm === basicForm;
        })
      ) {
        return categoriesForErpSystem[cur].find((categoryObj) => {
          const hasCatId: boolean = !!categoryObj.catId;
          if (hasCatId) {
            return (
              categoryObj.basicForm === basicForm && categoryObj.catId === catId
            );
          }
          return categoryObj.basicForm === basicForm;
        });
      }
      return acc;
    },
    {} as Category
  );

  if (typeof reducedCategory !== "undefined") {
    // @ts-ignore
    return reducedCategory.fields;
  }
  return [];
};
