import React, { useEffect, useState, lazy, Suspense } from "react";
import { FormField } from "../../types";
import { Box, LoadingIndicator } from "@eriksdigital/atomic-ui/components";
// @ts-ignore
import { OnChange } from "react-final-form-listeners";
import { useIntl } from "react-intl";
import { IProps } from "../../types";

import {
  Field,
  useFormState,
  AnyObject,
  useField,
  useForm,
} from "react-final-form";
import { createLabel } from "./utils";

const SelectAdapter = lazy(() => import("./../SelectAdapter"));
const TextFieldAdapter = lazy(() => import("./../TextFieldAdapter"));
const ColorAdapter = lazy(() => import("./../ColorAdapter"));
const FormTitle = lazy(() => import("./../FormTitle"));

const parseParamString: (str: string) => string[] = (str) => {
  if (!str.includes("|")) {
    return [str];
  }
  return str.split("|");
};

const areFieldsThruty: (fields: string[], values: AnyObject) => number = (
  fields,
  formVals
) => {
  return fields.reduce((counter, fieldId) => {
    if (formVals[fieldId]) {
      return counter + 1;
    }
    return counter;
  }, 0);
};

const GenericField: React.FC<FormField> = (props) => {
  const {
    id,
    component,
    attributeId,
    onChange,
    options,
    placeholder,
    hideIf,
    showModalIf,
    showModal,
    disabledIf,
    onClick,
    ...rest
  } = props;

  const [isDisabled, setIsDisabeld] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { formatMessage } = useIntl();
  const { values } = useFormState();
  const { mutators } = useForm();
  const {
    meta: { invalid, dirty, touched },
    input: { value },
  } = useField(id);

  const componentsMapper: {
    [k: string]: React.LazyExoticComponent<React.FC<IProps>>;
  } = {
    // @ts-ignore
    select: SelectAdapter,
    // @ts-ignore
    text: TextFieldAdapter,
    // @ts-ignore
    colour: ColorAdapter,
    title: FormTitle,
  };

  const Component = componentsMapper[component];
  const bottomMargin = component === "title" ? "sp4" : "sp12";
  const label = createLabel(id, formatMessage);

  useEffect(() => {
    if (component === "text" && dirty && !invalid) {
      return setIsSuccess(true);
    }
    return setIsSuccess(false);
  }, [invalid, dirty, touched, component]);

  useEffect(() => {
    if (disabledIf) {
      const disabledIfValues = parseParamString(disabledIf);
      const amountOfTruthyFields = areFieldsThruty(disabledIfValues, values);
      const isDisabled = amountOfTruthyFields !== disabledIfValues.length;
      setIsDisabeld(isDisabled);
    }
  }, [disabledIf, values]);

  const handleClick = (e: Event) => {
    if (onClick) {
      onClick(e);
    }
    if (showModalIf && showModal) {
      const showModalIfValues = parseParamString(showModalIf);
      const amountOfTruthyFields = areFieldsThruty(showModalIfValues, values);
      const shouldShowModal = amountOfTruthyFields !== showModalIfValues.length;
      if (shouldShowModal && showModal) {
        showModal(true);
      }
    }
  };

  const handleOnchange = () => {
    if (component === "text" && value) {
      return {
        onChange: mutators.setPositiveValue,
      };
    }
    return {};
  };

  if (hideIf) {
    return null;
  }

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Box marginBottom={bottomMargin} onClick={handleClick}>
        {/* @ts-ignore */}
        <Field
          component={Component as any}
          id={id}
          name={id}
          allowNull={true}
          aria_labeledby={`label_${label}`}
          isClearable={true}
          options={options}
          {...handleOnchange()}
          label={label}
          placeholder={placeholder}
          disabled={isDisabled}
          renderOptions={(option: {
            id: string;
            label: string;
            value: string;
          }) => ({
            id,
            label: option,
            value: option,
          })}
          success={isSuccess}
          {...rest}
        />
      </Box>
      {component !== "title" && onChange && (
        <OnChange name={attributeId || id}>{onChange}</OnChange>
      )}
    </Suspense>
  );
};

export { GenericField };
